<template>
    <div>
        <con-tab ></con-tab>
        <!--轮播图-->
        <home-swiper> </home-swiper>
        <need-nei ></need-nei>
    </div>
</template>

<script>
    import FromList from "@/common/input";
    import ConTab from "../../components/common/ConTab";
    import NeedNei from "./base/gaoxiao";
    import HomeSwiper from "../../components/common/Swiper";
    // import search from "./SearchCom/searchnei"
    import {lun_gou} from "../common/js/api";
    export default {
        name: "gaoxiao",
        components:{
            FromList,
            HomeSwiper,
            ConTab,
            NeedNei,
            // search
        },
        data(){
            return{
                search: "",
                imgList:[],
                ids:this.$route.query.id
            }
        },
        // beforeRouteEnter (to, from, next) {
        //     console.log(to, from.path) // 可以拿到 from， 知道上一个路由是什么，从而进行判断
        //     // if(){}
        //     //在next中写处理函数
        //     next(); // err 与 12134 是随便传的值， 可忽略
        // },
        created(){
            this.lun_bo()
        },
        methods:{
            // 服务商入驻
    settled() {
      this.$router.push({
        path: "/service_s",
      });
    },
            //机构云轮播
            lun_bo(){
                lun_gou({}).then((res) => {
                    this.imgList=res.data
                }).catch((err) => {
                });
            },
        }
    }
</script>

<style scoped lang="less">/* 服务商入驻 */
.Settle {
  position: absolute;
  top: 320px;
  left: 50%;
  transform: translateX(-50%);
  width: 550px;
  z-index: 2;
  background: url(../../assets/img/kefuyun/2.png) no-repeat center;
  // background-size: 100% 100%;
  padding-top: 45px;
  padding-left: 40px;
  // width: 100%;
  height: 160px;
}

.Settle span {
  padding-top: 28px;
  margin: 28px 0 5px;
  width: 362px;
  height: 56px;
  color: rgba(255, 255, 255, 100);
  font-size: 28px;
  font-weight: 700;
  text-align: left;
  font-family: Helvetica-regular;
  letter-spacing: 2px;
}

.Settle button {
  cursor: pointer;
  position: relative;
  margin-top: 20px;
  display: block;
  width: 140px;
  height: 40px;
  font-size: 14px;
  text-align: center;
  font-family: Microsoft Yahei;
  background-color: #fff;
  border: 1px solid rgba(255, 255, 255, 100);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.Settle button::after {
  content: ">";
  position: absolute;
  right: 16px;
  top: 13px;
  border-radius: 50%;
  color: #fff;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background-color: rgba(255, 59, 59, 100);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
    .swiper_class{
        position: relative;
    }
    .img_box {
        width: 100%;
        height: 451px;
       margin-top: 135px;
    }
    .nei_box{
        position: absolute;
        top: 200px;
        z-index: 9999;
    }
	
	@media screen and (max-width: 1200px) {
		.img_box {
		   margin-top: 15rem;
		}
	}
</style>