<template>
  <div class="big_container">
    <div class="box">
      <div class="box_one">
        <div class="title_flex">
          <div class="line"></div>
          <div class="text" v-if="ItemId == 108">高校</div>
          <div class="text" v-else-if="ItemId == 109">科研院所</div>
          <div class="text" v-else-if="ItemId == 110">其他科研组织</div>
          <div class="text" v-else-if="ItemId == 111">创新个人</div>
          <div class="text" v-else-if="ItemId == 307">企业</div>
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div class="botom_box">
      <!--蓝色-->
      <div class="top_box">
        <p>机构分类</p>
      </div>
      <div class="search_input">
        <el-input placeholder="请输入内容" v-model="keywords">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <span style="cursor: pointer" @click="List()">搜索</span>
      </div>
      <div></div>
      <!-- 筛选组件 -->
      <!-- <shai @name="getson" @fatherMethod="fatherMethod" ></shai> -->

      <!-- 筛选 -->
      <div class="resource-list-search">
        <div class="resource-search-content">
          <div class="select-content">
            <span class="select-field-title">服务分类：</span>
            <div class="select-field-list">
              <ul>
                <li
                  v-for="(item, key) in type"
                  :class="key == index2 ? 'cur' : ''"
                  @click="handle2(item, key)"
                  :key="item.id"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!--tab-->
      <div class="gao_line"></div>
      <!-- <div class="tab_box">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="综合" name="first">
                        <zong @total="sonss"  ref="child"></zong>
                        <div class="ye_box">
                            <el-pagination
                                    background
                                    @size-change="sizeChange"
                                    @current-change="currentChange"
                                    layout="total, prev, pager, next"
                                    :current-page.sync="pageNum"
                                    :page-size="pageSize"
                                    :total="total">
                            </el-pagination>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="热门优选" name="second">
                        <remen ref="childs"></remen>
                        <div class="ye_box">
                        <el-pagination
                                background
                                @size-change="sizeChange"
                                @current-change="currentChange"
                                layout="total, prev, pager, next"
                                :current-page.sync="pageNum"
                                :page-size="pageSize"
                                :total="total">
                        </el-pagination>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div> -->

      <div class="service-list">
        <div class="tabs">
          <span
            class="tabs-one"
            :class="idx == index1 ? 'active1' : ''"
            @click="Csort(i, idx)"
            v-for="(i, idx) in sort"
            :key="idx"
            >{{ i }}</span
          >
        </div>
        <div class="item-nav">
          <ul v-if="items.length !== 0">
            <li class="items" v-for="(i, idx) in items" :key="idx" @click="goTodetail(i)">
              <img
                alt=""
                :src="urlImg + i.img"
                class="items-image"
                lazy="loaded"
              />
              <p class="items-info">{{ i.title }}</p>
            </li>
          </ul>
          <div v-else>
            <div class="fruless">
              <div class="fruless-comtain">
                <img
                  src="../../../assets/img/qiyeyun/no.png"
                  alt="暂无内容"
                  class="fruless-image"
                />
                <div class="fruitless-tip">暂无内容</div>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="pagination">
            <pagination
              class="pages"
              v-show="total > 0"
              :total="total"
              layout="prev, pager, next"
              :page.sync="pageNum"
              :limit.sync="pageSize"
              @pagination="List"
            />
          </div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import shai from "../../../components/common/shai_xuan"
// import zong from "../common/zong"
// import remen from "../common/remen"
// import {schoolachievements} from "../../common/js/api";
import { eType } from "@/common/js/api";
import { employer_organ } from "../../common/js/api";
import Pagination from "@/common/Pagination";
import { urlImg } from "@/common/js/url";
export default {
  name: "gaoxiao",
  components: {
    //     shai,
    //     zong,
    //     remen
    Pagination,
  },
  data() {
    return {
      type: [],
      index2: -1,
      sort: ["综合", "热门优先"],
      total: 0,
      pageSize: 6,
      pageNum: 1,
      ids: "",
      keywords: "",
      items: [],
      ItemId: "",
      index1: 0,
    };
  },
  created() {
    this.ItemId = this.$route.query.id;
    this.urlImg = urlImg();
    this.getType();
    this.List();
    this.getindex();
  },
  // mounted(){
  //     this.search_input()
  // },
  methods: {
	  goTodetail(i){
		  this.$router.push({
		  	path: '/GaoDetail',
		  	query: {
		  		ids: i.id
		  	}
		  });
	  },
    // 类型
    getType() {
      eType({})
        .then((res) => {
          this.type = res.data;
        })
        .catch(() => {});
    },
    handle2: function (item, idx) {
      this.index2 = idx;
      this.ItemId = item.id;
      this.List();
    },
    getindex() {
      if (this.index1 == 0) {
        this.ids = 1;
        this.views = "";
      } else if (this.index1 == 1) {
        this.ids = "";
        this.views = 2;
      }
      this.List();
    },
    // 排序
    Csort(item, idx) {
      this.index1 = idx;
      this.getindex();
    },
    List() {
      employer_organ({
        keywords: this.keywords,
        views: this.views, //浏览量
        type: this.ItemId, //108高校，109科研院所，110其他科研组织，111创新个人
        page: this.pageNum,
        limits: this.pageSize,
        ids: this.ids, //1是綜合
      })
        .then((res) => {
          //   console.log(res);
          this.items = res.data.data;
          this.total = res.data.total;
        })
        .catch((err) => {});
    },
    // sonss(data) {
    //   this.total=data
    // },
    // //上一页下一页按钮
    // currentChange(val) {
    //     this.pageNum = val;
    //     this.search_input()
    // },
    // //每页显示几条数据按钮
    // sizeChange(val) {
    //     this.pageSize = val;
    //     this.search_input()
    // },
    // fatherMethod(name){
    //     this.hang=name
    //     this.search_input()
    // },
    // getson(data){
    //     this.keywords=data
    // },
    // //父组件搜索列表
    // search_input(){
    //     //    点击调用子组件方法 搜索
    //     var pageS=this.pageSize //页
    //     var pageN=this.pageNum  //页
    //     var par =this.input4  //搜索
    //     var hang=this.hang   //服务分类
    //     if(this.activeName=="first"){
    //         this.$refs.child.type_fei(par,hang,pageS,pageN);
    //     }else if(this.activeName=="second"){
    //         this.$refs.childs.type_fei(par,hang,pageS,pageN);  //第一个参数是搜索
    //     }
    // },
    // handleClick(tab, event) {
    //     this.search_input()
    // },
  },
};
</script>

<style scoped lang="less">
.resource-list-search {
  box-sizing: border-box;
  padding: 10px 40px 25px 70px;
  background: #ffffff;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  .select-content {
    margin-top: 15px;
    font-size: 14px;
    // height: 28px;
    line-height: 28px;
    .select-field-title {
      width: 100px;
      display: inline-block;
      color: #333;
      text-align: left;
      font-weight: 500;
    }
    .select-field-list {
      width: 980px;
      display: inline-block;
      li {
        padding: 0 15px;
        display: inline-block;
        color: #999;
        cursor: pointer;
      }
      li.cur {
        background: #2a71d2;
        border-radius: 2px;
        color: #fff;
      }
    }
  }
}

.fruless {
  background-color: #fff;
  padding: 0 0 100px;
  text-align: center;
  font-size: 20px;
  .fruless-comtain {
    margin: 0 auto;
    .fruless-image {
      display: inline-block;
      width: 530px;
      height: 404px;
    }
    .fruitless-tip {
      font-size: 20px;
      color: #696969;
      text-align: center;
    }
  }
}
.service-list {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
      padding: 0 20px;
  .tabs {
    padding: 19px 34px;
    background-color: #f7f7f7;
    .tabs-one {
      font-size: 18px;
      color: #696969;
      margin-right: 40px;
      cursor: pointer;
    }
    .active1 {
      color: #3892eb;
    }
  }
  .item-nav {
    margin-top: 30px;
    .items:nth-of-type(3n) {
      margin-right: 0;
    }
    .items:hover {
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
      border-radius: 5px;
    }
    .items {
      display: inline-block;
      width: 348px;
      margin-right: 50px;
      margin-bottom: 20px;
      transition: 0.3s;
      vertical-align: top;
      text-align: center;
      font-size: 18px;
      cursor: pointer;
      .items-image {
        display: inline-block;
        width: 348px;
        height: 214px;
      }
      .items-price {
        font-weight: 500;
        margin-top: 15px;
        color: #ff0707;
      }
      .items-info {
        margin-top: 15px;
        padding-bottom: 8px;
      }
    }
  }
}
.ye_box {
  margin: 15px 33%;
  width: 500px;
}
/deep/.el-tabs__active-bar {
  background-color: unset;
}
.gao_line {
  width: 100%;
  height: 25px;
  background-color: #f8f9fa;
}
.tab_box {
  overflow: hidden;
  margin: 30px;
}
/deep/.el-input__inner {
  background-color: #f7f7f7;
  border-radius: 25px;
}
/deep/ .el-tabs__nav-wrap {
  overflow: hidden;
  padding-left: 30px;
  width: 1154px;
  height: 64px;
  line-height: 64px;
  border-radius: 5px;
  background-color: rgba(247, 247, 247, 100);
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 100);
}
/deep/ .el-input {
  width: 976px;
  border-radius: 25px !important;
}
.big_container {
  background-color: #f8f9fa;
  width: 100%;
  height: auto;
}
.botom_box {
  width: 1200px;
  height: auto;
  background-color: #fff;
  margin: 0 auto;
  .search_input {
    margin: 30px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      color: rgba(0, 132, 255, 100);
      font-size: 14px;
      text-align: center;
    }
  }
  .top_box {
    width: 1200px;
    height: 40px;
    line-height: 20px;
    border-radius: 5px 5px 0px 0px;
    background-color: rgba(56, 146, 235, 100);
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 100);
    p {
      line-height: 40px;
      color: rgba(248, 249, 250, 100);
      font-size: 20px;
    }
  }
}
.box {
  width: 100%;
  margin: 0 auto;
  .box_one {
  }
  .title_flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .text {
      font-size: 28px;
      color: #3892eb;
      font-weight: 600;
    }
    .line {
      margin: 100px;
      width: 60px;
      height: 1px;
      background-color: #3892eb;
    }
  }
}
@media screen and (max-width: 1200px) {
	.botom_box{
		width: 98%;
		overflow: hidden;
	}
	.botom_box .top_box{
		width: 100%;
		height: 3rem;
	}
	.box .title_flex .text{
		font-size: 2.2rem;
	}
	.botom_box .top_box p{
		font-size: 2rem;
		line-height: 3rem;
	}
	.botom_box .search_input{
		margin: 1.5rem;
	}
	/deep/.el-input{
		width: 86%;
		border-radius: 50px !important;
	}
	/deep/.el-input__inner{
		height: 3.5rem;
		font-size: 1.6rem;
		padding-left: 3rem;
	}
	/deep/.el-input__prefix{
		font-size: 1.8rem !important;
		top: 0.8rem;
		left: 0.7rem;
	}
	.botom_box .search_input span{
		font-size: 1.8rem;
	}
	.resource-list-search{
		padding: 0.5rem 1.5rem 1rem;
	}
	.resource-list-search .select-content .select-field-title{
		font-size: 1.8rem;
		width: auto;
		padding-bottom: 1.3rem;
	}
	.resource-list-search .select-content .select-field-list li{
		font-size: 1.6rem;
	}
	.service-list .tabs .tabs-one{
		font-size: 1.6rem;
	}
	.service-list .item-nav ul{
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.service-list .item-nav .items{
		margin: 0 0 2rem 0;
		width: 48%;
	}
	.service-list .item-nav .items .items-image{
		width: 100%;
		height: 15rem;
	}
	.service-list .item-nav .items .items-info{
		font-size: 1.6rem;
	}
}
</style>